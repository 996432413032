<template>
    <div id="list-tank-component" class="pa-4">
        <!-- tanks.filter(tank => tank.is_subscription_device_active).length > 0 ? -->
        <template v-if="!loading_tanks">

            <div v-if="tanks.length > 0">
                <v-text-field
                    v-model="search"
                    :label="$t('global.search')"
                    class="white-text-field mb-2"
                    filled
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                />
                <p class="font-weight-bold mb-4">{{ 'Vous avez ' + tanks.length + ' cuve(s)' }}</p>

                <v-row>
                    <v-col v-for="tank in tanks_copy" :key="tank.id_asset" cols="12" lg="4" md="6" class="mt-2">
                        <!-- TODO: Just for apple store -> condition to remove
                         v-if="tank.is_subscription_device_active"
                         -->
                        <v-card class="elevation-0 fill-height " :id="'tank-card-'+tank.id_asset" data-cy="tank-card">
                            <!------------------------
                                BADGE ALERT START
                            ------------------------->
                            <v-row class="line-alert">
                                <div class="order-tooltip-wrapper" id="order-tooltip-wrapper" data-cy="order-tooltip-wrapper">
                                    <v-btn color="error" elevation="0" fab small
                                           v-if="$store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(order => order.asset.id_asset === tank.id_asset).length === 1"
                                           class="bounce-btn mr-1"
                                           data-cy="badge-orders-cockpit-pending-validation"
                                    >
                                        <v-icon color="white">mdi-tanker-truck</v-icon>
                                    </v-btn>
                                    <div class="order-tooltip-content" id="order-tooltip-content" data-cy="order-tooltip-content">
                                         <span class="font-weight-bold secondary--text ">
                                            Vous avez une proposition de commande à valider
                                        </span>

                                        <v-btn color="primary" class="font-weight-bold mt-2"
                                               @click="$router.push('orders_cockpit')"
                                        >
                                            <span class="secondary--text">Accéder</span>
                                        </v-btn>

                                        <div id="order-tooltip-content-arrow" class="order-tooltip-content-arrow"></div>
                                    </div>
                                </div>

                                <section v-if="!loading_alerts">
                                    <v-btn
                                        v-for="alert in alerts.filter(alert_filter => alert_filter.id_device === tank.devices[0].id_device && alert_filter.user_alert_preference ? alert_filter.user_alert_preference.notification_badge : false)
                                        .sort((a, b) => Number(b.triggered) - Number(a.triggered)).slice(0, 5 - $store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(order => order.asset.id_asset === tank.id_asset).length)"
                                        :key="alert.key"
                                        :class="(alert.triggered && !alert.acquitted ? 'bounce-btn' : '') + ' mr-1'"
                                        :color="alert.acquitted ? 'grey' : alert.triggered ? alert.configuration_appearance.color_triggered : alert.configuration_appearance.color_untriggered"
                                        elevation="0" fab small
                                        @click="alert.acquitted ? openDialogAcquitAlert(alert) : alert.triggered ? openDialogAcquitAlert(alert) : openDialogUpdateAlert(false, alert)"
                                    >
                                        <v-icon color="white">
                                            mdi-{{alert.triggered ? alert.configuration_appearance.icon_triggered : alert.configuration_appearance.icon_untriggered}}
                                        </v-icon>
                                    </v-btn>
                                </section>

                                <v-btn v-else fab small elevation="1" loading/>

                                <v-btn
                                    v-if="alerts.filter(alert => alert.id_device === tank.devices[0].id_device).length > 6"
                                    color="accent pr-1" elevation="0" fab small>
                                    <span class="font-20">+6</span>
                                </v-btn>
                            </v-row>
                            <!------------------------
                                 BADGE ALERT END
                             ------------------------->

                            <v-card-title class="font-weight-bold pb-2">
                                <!--
                                <v-icon v-if="fav" class="mr-2" @click="favorite">mdi-star-outline</v-icon>
                                <v-icon v-else class="mr-2" color="#F3BA23" @click="favorite">mdi-star</v-icon>
                                -->

                                <v-alert text type="warning" icon="mdi-alert-outline"
                                         v-if="!tank.is_all_subscription_device_active && tank.is_subscription_device_active"
                                >
                                    <template v-for="device in tank.devices">
                                        <p :key="device.id_device" v-if="!device.is_subscription_active"
                                           class="font-weight-regular font-14 warning--text line-height-18 mb-0"
                                        >
                                            L'abonnement pour le capteur {{ device.device_number }} a expiré le <strong>
                                            {{ moment(device.date_subscription_end).format('DD MMMM YYYY') }}</strong>.
                                            <br>
                                        </p>
                                    </template>

                                    <p class="font-weight-regular font-14 warning--text line-height-18 mb-0">
                                        Pour accéder de nouveau à tous le détail de votre cuve, merci de réactivez votre
                                        abonnement depuis votre espace SENS
                                        <a target="_blank" href="http://dashboard.fuel-it.io/"
                                           class="warning--text font-weight-bold">http://dashboard.fuel-it.io/</a>
                                    </p>
                                </v-alert>

                                <v-alert
                                    text type="warning" icon="mdi-alert-outline"
                                    v-else-if="!tank.is_all_subscription_device_active"
                                >
                                    <p class="font-weight-regular font-14 warning--text line-height-18 mb-0">
                                        Votre abonnement à expiré {{ tank.devices.length > 1 ? ' pour l\'ensemble de vos capteurs.' : '' }}.
                                        Pour accéder de nouveau aux détails de votre cuve, merci de réactiver votre abonnement depuis votre espace SENS
                                        <a target="_blank" href="http://dashboard.fuel-it.io/" class="warning--text font-weight-bold">
                                            http://dashboard.fuel-it.io/
                                        </a>
                                    </p>
                                </v-alert>

                                <span data-cy="tank-card-name" class="pointer" @click="pushTankDetailsRoute(tank)">{{ tank.name }}</span>

                                <v-spacer/>

                                <v-menu offset-y v-if="tank.is_all_subscription_device_active && tank.is_subscription_device_active">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            @click="openBottomSheetEditTank(tank)"
                                            v-bind="attrs"
                                            v-on="on"
                                            data-cy="btn-option-asset"
                                        >
                                            mdi-dots-vertical
                                        </v-icon>
                                    </template>

                                    <v-list v-if="!is_mobile">
                                        <v-list-item :to="{ path: '/equipment/tank/' + tank.id_asset}">
                                            <v-list-item-icon class="my-auto mr-6">
                                                <v-icon>mdi-eye-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title class="my-0">
                                                <span>Voir le détail de la cuve</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            class="pointer"
                                            v-if="userHasAccess('TNK', role_admin)"
                                            @click="openDialogEditEquipment(tank)"
                                            data-cy="manage-asset-btn"
                                        >
                                            <v-list-item-icon class="my-auto mr-6">
                                                <v-icon>mdi-pencil-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title class="my-0">
                                                <span>Editer la cuve</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item :to="{ path: '/equipment/manage_alert/' + tank.id_asset}" v-if="userHasAccess('BASE', role_vision)" data-cy="manage-alert-link">
                                            <v-list-item-icon class="my-auto mr-6">
                                                <v-icon>mdi-bell-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title class="my-0">
                                                <span>Gérer les alertes</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-card-title>

                            <!--                        <v-card-subtitle class="ml-8">-->
                            <v-card-subtitle class="pointer" @click="pushTankDetailsRoute(tank)">
                                <p class="font-weight-light font-12 ml-n1 mb-2 text-overflow-ellipsis">
                                    <v-icon dense>mdi-map-marker-outline</v-icon>
                                    <span data-cy="tank-card-address">{{ getFormattedAddress(tank.domicile) }}</span>
                                </p>

                                <p class="mb-0">
                                    <span v-if="tank.devices">N° capteur(s) :</span>
                                    <span v-for="(device, index) in tank.devices" :key="device.id" data-cy="tank-card-devices">
                                        {{ device.device_number }} {{ tank.devices.length !== index + 1 ? ' - ' : '' }}
                                    </span>
                                </p>

                                <chips-last-statement
                                    v-if="tank.date_last_seen && tank.is_all_subscription_device_active && tank.is_subscription_device_active"
                                    :danger="345600"
                                    :date="tank.date_last_seen"
                                    :warning="172800"
                                    previous_text="Mise à jour"
                                    class="pointer"
                                />
                                <v-chip v-else outlined small>Pas de mise à jour</v-chip>
                            </v-card-subtitle>

                            <v-card-text class="pb-8 pointer" @click="pushTankDetailsRoute(tank)">
                                <v-row align="center" justify="center" class="ma-0 mb-2" style="height: 180px">
                                    <v-col>
                                        <display-liquid-level-component
                                            v-if="tank.is_subscription_device_active"
                                            :actual_volume="tank.actual_volume"
                                            :id_asset="tank.id_asset"
                                            :show_indicator="true"
                                            :to="{ path: '/tank/' + tank.id}"
                                            :volume_total="tank.volume_total"
                                            :type_asset="tank.type"
                                            :ref="'DisplayLiquidLevelComponent-' + tank.id_asset"
                                            data-cy="display-liquid-level-component"
                                        />

                                        <v-img v-else :src="getAssetImagePath(tank)"
                                               aspect-ratio="1" contain class="mb-4"
                                               :height="tank_image_settings[tank.type] ? tank_image_settings[tank.type].height : 100"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="text-center font-12">
                                    <v-col>
                                        <span class="font-color-medium">Volume</span>
                                        <p class="mb-0 font-weight-bold">
                                            {{
                                                tank.is_subscription_device_active ? numberWithSpaces(Math.round(tank.actual_volume)) + ' L' : '-'
                                            }}
                                        </p>
                                    </v-col>
                                    <v-divider vertical color="white"/>

                                    <v-col>
                                        <span class="font-color-medium">Creux</span>
                                        <p class="mb-0 font-weight-bold">
                                            {{
                                                tank.is_subscription_device_active ? numberWithSpaces(Math.round(tank.hollow)) + ' L' : '-'
                                            }}
                                        </p>
                                    </v-col>
                                    <v-divider vertical color="white"/>

                                    <v-col>
                                        <span class="font-color-medium">Niveau</span>
                                        <p class="mb-0 font-weight-bold">
                                            {{
                                                tank.is_subscription_device_active ? numberWithSpaces(Math.round(tank.remaining_level)) + ' %' : '-'
                                            }}
                                        </p>
                                    </v-col>
                                    <v-divider vertical color="white"/>

                                    <v-col>
                                        <span class="font-color-medium">Contenu</span>
                                        <p class="mb-0 font-weight-bold">
                                            {{ tank.is_subscription_device_active ? tank.asset_liquid.name : '-' }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-bottom-sheet v-model="edit_tank_bottom_sheet" v-if="is_mobile">
                    <v-sheet>
                        <v-list>
                            <v-list-item @click="pushTankDetailsRoute(selected_tank)">
                                <v-list-item-icon class="my-auto mr-6">
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="my-0">
                                    <span>Voir le détail de la cuve</span>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="pointer" v-if="userHasAccess('TNK', role_admin)"
                                         @click="openDialogEditEquipment(selected_tank)"
                            >
                                <v-list-item-icon class="my-auto mr-6">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="my-0">
                                    <span>Editer la cuve</span>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item :to="{ path: '/equipment/manage_alert/' + selected_tank.id_asset}" v-if="userHasAccess('BASE', role_vision)" data-cy="manage-alert-link">
                                <v-list-item-icon class="my-auto mr-6">
                                    <v-icon>mdi-bell-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="my-0">
                                    <span>Gérer les alertes</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-sheet>
                </v-bottom-sheet>
            </div>

            <div v-else>
                <none-equipment-install-component asset_type="cuve"/>
            </div>
        </template>

        <v-row v-else class="mt-2">
            <v-col v-for="n in 6" :key="n" cols="12" lg="4" md="6">
                <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead"/>
            </v-col>
        </v-row>

        <v-btn fab color="secondary" elevation="0"
               :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
                :href="getTunnelInstallationUrl()" target="_blank"
               v-if="userHasAccess('TNK', role_admin)"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>

        <manage-equipment-devices-component
            v-if="userHasAccess('TNK', this.role_edit)"
            ref="manageEquipmentDevices"
            data-cy="manage-equipment-devices"
        />

        <manage-equipment-component v-if="userHasAccess('TNK', this.role_admin)" ref="manageEquipmentComponent"
                          :asset="tank_selected" v-on:refreshTank="getTanksByClient"
                          data-cy="manage-equipment-component"
        />

        <dialog-update-alert-component v-if="dialog_alert && userHasAccess('BASE', this.role_edit)" ref="DialogUpdateAlertComponent"
                                       :alert="dialog_alert" :is_new_alert="is_new_alert"
                                       data-cy="dialog-update-alert-component"
        />

        <v-dialog v-model="dialog_acquit_alert" max-width="500px">
            <v-card>
                <v-card-title>Alerte</v-card-title>

                <v-card-text>
                    <v-row class="ma-0">
                        <v-checkbox color="primary" v-model="acquit_alert.acquitted">
                            <template v-slot:label>
                                <span>Aquitter l'alerte</span>
                            </template>
                        </v-checkbox>

                        <v-tooltip top max-width="250px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" class="ml-2" v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>
                            <span class="white--text">
                                L'aquittement permet de ne plus envoyer d'alertes aux utilisateurs jusqu'au retour à l'état inital.
                            </span>
                        </v-tooltip>
                    </v-row>

                    <v-divider class="mb-4"/>

                    <p class="font-color-medium font-weight-bold font-14">Evénements</p>

                    <section v-if="acquit_alert_actions.length > 0 && !loading_actions">
                        <v-row class="ma-0 my-2" v-for="action in acquit_alert_actions" :key="action.date_event">
                            <v-icon class="mr-2">mdi-{{ display_acquit_alert_actions[action.message].icon }}</v-icon>
                            <span>Alerte {{ display_acquit_alert_actions[action.message].message }} {{ moment(action.date_event).from() }} </span>
                            <span v-if="display_acquit_alert_actions[action.message].by">&#8239; par {{action.user.first_name + ' ' + action.user.last_name}}</span>
                        </v-row>
                    </section>

                    <span v-else>Aucun evénements récent</span>

                    <v-progress-linear :indeterminate="loading_actions" :color="loading_actions ? 'primary' : '#939598'" height="1px" class="my-4"/>

                    <span
                        v-if="userHasAccess('BASE', this.role_edit)"
                        class="text-uppercase secondary--text font-16 text-decoration-underline font-weight-bold pointer"
                        @click="openDialogUpdateAlert(false, acquit_alert)"
                    >
                        Accéder à la configuration
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_acquit_alert = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn class="btn-secondary-loader" color="secondary" text
                           @click="prepareUpdateAcquitAlert()" :loading="loading_acquit_update"
                    >
                        <span class="secondary--text font-weight-bold">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="bottom-hidden-div"/>
    </div>
</template>

<script>
import ManageEquipmentDevicesComponent from "@/components/EquipmentManagement/ManageEquipmentDevicesComponent";
import DisplayLiquidLevelComponent from "./LiquidLevelComponent/DisplayLiquidLevelComponent";
import ChipsLastStatement from "@/components/Global/ChipsLastStatement";
import ManageEquipmentComponent from "@/components/EquipmentManagement/ManageEquipmentComponent";
import NoneEquipmentInstallComponent from "@/components/Equipment/NoneEquipmentInstallComponent";
import DialogUpdateAlertComponent from "@/components/Alert/DialogUpdateAlertComponent";
import AlertRepository from "@/repositories/AlertRepository";

export default {
    name: "ListTankEquipmentComponent",
    components: {
        DialogUpdateAlertComponent,
        NoneEquipmentInstallComponent,
        DisplayLiquidLevelComponent,
        ManageEquipmentComponent,
        ManageEquipmentDevicesComponent,
        ChipsLastStatement
    },
    props: {
        asset_type: {
            type: Object,
            required: true,
        }
    },
    created() {
        this.getTanksByClient();
    },
    data() {
        return {
            loading_tanks: false,
            selected_tank: {},
            edit_tank_bottom_sheet: false,
            dialog_manage_devices: false,

            tanks_copy: [],

            search: '',
            tank_selected: {},

            fav: false,

            tank_image_settings: {
                1: {
                    path: 'tank_cylindrical.png',
                    height: 180
                },
                2: {
                    path: 'tank_rectangular.png',
                    height: 100
                },
                3: {
                    path: 'IBC.png',
                    height: 120
                },
                default: {
                    path: 'tank.png',
                    height: 100
                }
            },

            is_new_alert: false,
            dialog_alert: {},

            dialog_acquit_alert: false,
            acquit_alert: {},
            loading_acquit_update: false,
            loading_alerts: false,
            acquit_alert_actions: [],
            loading_actions: false,
            display_acquit_alert_actions: {
                "untriggered": {
                    icon: '',
                    message: '',
                    by: false
                },
                "triggered": {
                    icon: 'alert-circle-outline',
                    message: 'déclenché',
                    by: false
                },
                "disclaimer": {
                    icon: 'close-circle-multiple-outline',
                    message: 'révoquée',
                    by: true
                },
                "acquit": {
                    icon: 'alert-circle-check-outline',
                    message: 'acquittée',
                    by: true
                },
            }
        }
    },
    computed: {
        tanks: {
            get: function () {
                return this.$store.getters['tanks/tanks'];
            },
            set: function (value) {
                this.$store.dispatch('tanks/getTanksByClient', this.$store.getters['user_connected/user_connected'].id_client);
            },
        },
        alerts: {
            get: function () {
                return this.$store.getters['alerts/alerts'];
            }
        }
    },
    methods: {
        getTanksByClient() {
            this.loading_tanks = true;

            this.$store.dispatch('tanks/getTanksByClient', {
                id_client: this.$store.getters['user_connected/user_connected'].id_client,
                id_referential: this.asset_type.id_referential
            }).then(() => {
                this.tanks_copy = JSON.parse(JSON.stringify(this.tanks));
                this.getAlerts();
                this.avoidTooltipOffScreen();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_tanks = false;
            });
        },

        favorite() {
            this.fav = !this.fav;
        },

        openBottomSheetEditTank(tank) {
            this.selected_tank = tank;
            this.edit_tank_bottom_sheet = true;
        },

        swipeBottomHandler() {
            this.edit_tank_bottom_sheet = false;
        },

        openDialogEditEquipment(tank) {
            this.edit_tank_bottom_sheet = false;
            this.tank_selected = JSON.parse(JSON.stringify(tank));
            this.$refs.manageEquipmentComponent.dialog_manage_equipment = true;
        },

        getAssetImagePath(tank) {
            let theme = this.$vuetify.theme.dark ? 'dark' : 'light';
            let path = '/images/asset/' + theme + '/tank.png';

            if (this.tank_image_settings[tank.type]) {
                path = '/images/asset/' + theme + '/' + this.tank_image_settings[tank.type].path;
            }

            return path;
        },

        getAlerts() {
            this.loading_alerts = true;
            this.$store.dispatch('alerts/getAlerts', this.tanks_copy).then(() => {
                //refresh tank display
                this.tanks_copy.forEach(tank => {
                    //avoid error during fast navigation
                    if(tank.is_subscription_device_active && this.$router.currentRoute.name === 'equipment') {
                        this.$refs['DisplayLiquidLevelComponent-' + tank.id_asset][0].prepareSetVolumeForLiquidComponent();
                    }
                });
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_alerts = false;
            });
        },

        openDialogUpdateAlert(is_new_alert, alert) {
            if(this.userHasAccess('BASE', this.role_edit)) {
                this.$refs.DialogUpdateAlertComponent.getMetrics(alert.id_device);
                this.dialog_alert = JSON.parse(JSON.stringify(alert));
                this.is_new_alert = is_new_alert;
                this.$refs.DialogUpdateAlertComponent.dialog_edit_alert = true;
            }
        },

        prepareUpdateAcquitAlert() {
            this.loading_acquit_update = true;
            if (this.acquit_alert.acquitted) {
                AlertRepository.acquittedAlert(this.acquit_alert.id_alert).then(() => {
                    this.showSnackbar('success', 'Alerte acquittée');
                }).catch((error) => {
                    this.manageError(error)
                }).finally(() => {
                    this.loading_acquit_update = false;
                    this.dialog_acquit_alert = false;
                    this.getAlerts();
                });
            } else {
                AlertRepository.unAcquittedAlert(this.acquit_alert.id_alert).then(() => {
                    this.showSnackbar('success', 'Alerte dé-acquittée');
                }).catch((error) => {
                    this.manageError(error)
                }).finally(() => {
                    this.loading_acquit_update = false;
                    this.dialog_acquit_alert = false;
                    this.getAlerts();
                });
            }
        },

        openDialogAcquitAlert(alert) {
            this.loading_actions = true;
            this.acquit_alert_actions = [];

            AlertRepository.getAlertEvents(alert.id_alert).then((success) => {
                this.acquit_alert_actions = success.data.data.sort((a, b) => {
                    if(this.moment(a.date_event).valueOf() < this.moment(b.date_event).valueOf()) return 1;
                    if(this.moment(a.date_event).valueOf() > this.moment(b.date_event).valueOf()) return -1;
                    return 0;
                });
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
               this.loading_actions = false;
            });

            this.acquit_alert = JSON.parse(JSON.stringify(alert));
            this.dialog_acquit_alert = true;
        },

        avoidTooltipOffScreen() {
            setTimeout(() => {
                let tooltips = document.getElementsByClassName('order-tooltip-content');
                let arrows = document.getElementsByClassName('order-tooltip-content-arrow');
                Array.prototype.forEach.call(document.getElementsByClassName('order-tooltip-wrapper'), (el, index) => {
                    //avoid error during fast navigation
                    if(this.$router.currentRoute.name === 'equipment') {
                        el.addEventListener('mouseover', (e) => {
                            if (window.innerWidth < e.pageX + 160) {
                                tooltips[index].style.left = '-85px';
                                arrows[index].style.left = '70%';
                            } else {
                                tooltips[index].style.left = '-55px';
                                arrows[index].style.left = '50%';
                            }
                        });
                    }
                });
            }, 500);
        },

        pushTankDetailsRoute(tank) {
            if(tank.is_subscription_device_active) {
                this.$router.push('/equipment/tank/' + tank.id_asset);
            }
        }
    },
    watch: {
        search: {
            handler() {
                if (!this.search) {
                    this.tanks_copy = this.tanks;
                } else {
                    let tanks_local = [];
                    this.tanks.forEach(tank => {
                        if (tank.name.includes(this.search)) {
                            tanks_local.push(tank);
                        }
                    });

                    this.tanks_copy = tanks_local;
                }
            },
            deep: true
        }
    }
}
</script>

<style>
.line-alert {
    position: absolute;
    top: -13px;
    right: 20px;
}

.order-tooltip-content {
    visibility: hidden;
    position: absolute;
    background: white;
    width: 150px;
    bottom: 50px;
    left: -55px;
    padding: 10px;
    border-radius: 2px;
    text-align: center;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 20%));
    opacity: 0;
    z-index: 99;
    display: none;
}

.order-tooltip-content-arrow {
    content: " ";
    position: absolute;
    bottom: -10px; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.order-tooltip-wrapper:hover .order-tooltip-content {
    visibility: visible;
    opacity: 1;
    display: block;
}
</style>