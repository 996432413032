<template>
    <svg width="45" height="31" viewBox="0 0 45 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" class="background-svg"
              d="M15.5109 0C14.5444 0 13.7609 0.783501 13.7609 1.75V21.935C13.3241 22.0422 13 22.4363 13 22.9062V26.951C13 27.5032 13.4477 27.951 14 27.951H15.2729L15.2729 27.9515V29.4627C15.2729 29.8769 15.6086 30.2127 16.0229 30.2127C16.4371 30.2127 16.7729 29.8769 16.7729 29.4627V27.9515L16.7729 27.951H27.3618V27.9515V29.4627C27.3618 29.8769 27.6976 30.2127 28.1118 30.2127C28.526 30.2127 28.8618 29.8769 28.8618 29.4627V27.9515V27.951H30.1342C30.6865 27.951 31.1342 27.5032 31.1342 26.951V22.9062C31.1342 22.4361 30.8099 22.0418 30.3728 21.9348V1.75C30.3728 0.783502 29.5893 0 28.6228 0H15.5109ZM28.8728 23.4062V23.4178H29.6342V26.451H14.5V23.4178H15.2609V23.4062H22.0671H28.8728ZM28.8728 21.9062V1.75C28.8728 1.61193 28.7608 1.5 28.6228 1.5H15.5109C15.3728 1.5 15.2609 1.61193 15.2609 1.75V21.9062H22.0671H28.8728ZM22.8171 15.7798C24.1295 15.4458 25.1002 14.2561 25.1002 12.8398C25.1002 11.1644 23.7421 9.8062 22.0667 9.8062C20.3913 9.8062 19.0331 11.1644 19.0331 12.8398C19.0331 14.2564 20.0042 15.4463 21.3171 15.78V12.8393C21.3171 12.4251 21.6529 12.0893 22.0671 12.0893C22.4813 12.0893 22.8171 12.4251 22.8171 12.8393V15.7798ZM22.0667 17.3733C24.5705 17.3733 26.6002 15.3436 26.6002 12.8398C26.6002 10.3359 24.5705 8.3062 22.0667 8.3062C19.5629 8.3062 17.5331 10.3359 17.5331 12.8398C17.5331 15.3436 19.5629 17.3733 22.0667 17.3733Z"
        />
    </svg>
</template>

<script>
export default {
    name: "BoilerSvg"
}
</script>