<template>
    <div id="none-equipment-install-component" class="in-progress-wrapper">
        <span class="h1-error">Vous n'avez pas de {{ asset_type }} installer pour le moment</span>
        <span class="font-14 font-color-medium mt-2">Pour installer une {{ asset_type }}  veuillez cliquer sur le bouton +</span>
    </div>
</template>

<script>

export default {
    name: 'NoneEquipmentInstallComponent',
    props: {
        asset_type: {
            type: String,
            require: true
        }
    }
}
</script>