<template>
    <div id="in-progress-component" class="in-progress-wrapper">
        <span class="h1-error">{{ $t('global.soon_available') }}</span>
        <span class="font-14 font-color-medium">{{ $t('global.module_under_construction') }}</span>
        <img src="/images/error/in_progress.png" class="in-progress-image" alt="in progress image">
    </div>
</template>

<script>

export default {
    name: 'InProgressComponent'
}
</script>

<style>

.in-progress-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    width: 100%;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.in-progress-image {
    max-width: 400px;
    width: 100%;
}

</style>