<template>
    <svg width="45" height="31" viewBox="0 0 45 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" class="background-svg"
              d="M15.6641 0C16.0784 0 16.4141 0.335787 16.4141 0.75V5.49743C16.4141 5.5287 16.4122 5.55952 16.4085 5.58978H32.7859C39.5271 5.58978 44.9919 11.0546 44.9919 17.7958C44.9919 24.537 39.5271 30.0018 32.7859 30.0018H13.206C6.46482 30.0018 1 24.537 1 17.7958C1 11.0546 6.46482 5.58978 13.206 5.58978H14.9198C14.9161 5.55952 14.9141 5.5287 14.9141 5.49743V0.75C14.9141 0.335787 15.2499 0 15.6641 0ZM31.7272 7.08978H27.1593V9.83095H31.7272V7.08978ZM25.6593 7.08978H13.206C8.42476 7.08978 4.37555 10.224 3.00086 14.55H25.6593V10.5809V7.08978ZM25.6593 16.05H2.64163C2.54846 16.6181 2.5 17.2013 2.5 17.7958C2.5 23.7086 7.29325 28.5018 13.206 28.5018H32.7859C38.6987 28.5018 43.4919 23.7086 43.4919 17.7958C43.4919 17.2013 43.4435 16.6181 43.3503 16.05H33.2272V22.3788C33.2272 22.793 32.8914 23.1288 32.4772 23.1288C32.063 23.1288 31.7272 22.793 31.7272 22.3788V19.9828H27.1678L27.1593 19.9828V22.3788C27.1593 22.793 26.8235 23.1288 26.4093 23.1288C25.9951 23.1288 25.6593 22.793 25.6593 22.3788V16.05ZM31.7272 16.05V18.4828H27.1678L27.1593 18.4829V16.05H31.7272ZM31.7272 14.55H27.1593V11.3309H31.7272V14.55ZM33.2272 14.55H42.9911C41.6589 10.3578 37.815 7.28473 33.2272 7.09871V14.55Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "TankSvg"
}
</script>