<template>
    <div id="list-hive-equipment-component" class="pa-4">
        <in-progress-component/>
    </div>
</template>

<script>
import InProgressComponent from "@/components/Error/InProgressComponent";

export default {
    name: "ListHiveEquipmentComponent",
    components: {InProgressComponent}
}
</script>
