<template>
    <div id="equipment-page">
        <toolbar-component v-bind:title="$store.getters['asset_types/asset_types_which_have_asset'].length === 1 ?
            $t(`equipment_page.${$store.getters['asset_types/asset_types_which_have_asset'][0].key}`) : 'Equipements'"
        />

        <v-tabs v-if="asset_types.length > 1" background-color="secondary" slider-color="primary"
                active-class="primary--text" class="tabs-asset-types default-arrows-tabs" v-model="tab_selected"
                show-arrows
        >
            <template v-if="asset_types.some(asset_type => asset_type.key === 'cuve')">
                <v-tab href="#equipment-tank"
                       :class="tab_selected === 'equipment-tank' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <tank-svg/>
                    <span class="ml-2 primary--text">{{ $t('equipment_page.tank') }}</span>
                </v-tab>
                <v-tab-item value="equipment-tank" class="background">
                    <list-tank-equipment-component
                        :asset_type="asset_types.filter(asset_type => asset_type.key === 'cuve')[0]"
                    />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'silo')">
                <v-tab href="#equipment-silo"
                       :class="tab_selected === 'equipment-silo' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <silo-svg/>
                    <span class="primary--text">Silos</span>
                </v-tab>
                <v-tab-item value="equipment-silo" class="background">
                    <list-silo-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'chaudiere')">
                <v-tab href="#equipment-boiler"
                       :class="tab_selected === 'equipment-boiler' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <boiler-svg/>
                    <span class="primary--text">{{ $t('equipment_page.boiler') }}</span>
                </v-tab>
                <v-tab-item value="equipment-boiler" class="background">
                    <list-boiler-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'hive')">
                <v-tab href="#equipment-hive"
                       :class="tab_selected === 'equipment-hive' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <hive-svg/>
                    <span class="primary--text">{{ $t('equipment_page.hive') }}</span>
                </v-tab>
                <v-tab-item value="equipment-hive" class="background">
                    <list-hive-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'ouvrage')">
                <v-tab href="#equipment-ouvrage"
                       :class="tab_selected === 'equipment-ouvrage' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <ouvrage-svg/>
                    <span class="ml-2 primary--text">{{ $t('equipment_page.ouvrage') }}</span>
                </v-tab>
                <v-tab-item value="equipment-ouvrage" class="background">
                    <list-ouvrage-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'enrouleur')">
                <v-tab href="#equipment-reel"
                       :class="tab_selected === 'equipment-reel' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <reel-svg/>
                    <span class="ml-1 primary--text">{{ $t('equipment_page.reel') }}</span>
                </v-tab>
                <v-tab-item value="equipment-reel" class="background">
                    <list-reel-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'ground_water')">
                <v-tab href="#equipment-ground-water"
                       :class="tab_selected === 'equipment-ground-water' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <ground-water-svg/>
                    <span class="ml-2 primary--text">{{ $t('equipment_page.ground_water') }}</span>
                </v-tab>
                <v-tab-item value="equipment-ground-water" class="background">
                    <list-ground-water-equipment-component/>
                </v-tab-item>
            </template>

            <template v-if="asset_types.some(asset_type => asset_type.key === 'generic')">
                <v-tab href="#equipment-hive"
                       :class="tab_selected === 'equipment-hive' ? 'primary--text' : 'primary--text tab-opacity'"
                >
                    <hive-svg/>
                    <span class="ml-2 primary--text">{{ $t('equipment_page.hive') }}</span>
                </v-tab>
                <v-tab-item value="equipment-hive" class="background">
                    <list-hive-equipment-component/>
                </v-tab-item>
            </template>
        </v-tabs>

        <template v-else-if="asset_types.length === 1">
            <list-tank-equipment-component v-if="asset_types[0].key === 'cuve'"
                                           :asset_type="asset_types.filter(asset_type => asset_type.key === 'cuve')[0]"
            />

            <list-boiler-equipment-component v-if="asset_types[0].key === 'chaudiere'"/>

            <list-silo-equipment-component v-if="asset_types[0].key === 'silo'"/>

            <list-hive-equipment-component v-if="asset_types[0].key === 'hive'"/>

            <list-ouvrage-equipment-component v-if="asset_types[0].key === 'ouvrage'"/>

            <list-reel-equipment-component v-if="asset_types[0].key === 'enrouleur'"/>

            <list-ground-water-equipment-component v-if="asset_types[0].key === 'ground_water'"/>

            <list-hive-equipment-component v-if="asset_types[0].key === 'generic'"/>
        </template>
    </div>
</template>

<script>
import ToolbarComponent from "@/components/Global/ToolbarComponent";

import ListBoilerEquipmentComponent from "@/components/Equipment/ListBoilerEquipmentComponent";
import ListGroundWaterEquipmentComponent from "@/components/Equipment/ListGroundWaterEquipmentComponent";
import ListHiveEquipmentComponent from "@/components/Equipment/ListHiveEquipmentComponent";
import ListOuvrageEquipmentComponent from "@/components/Equipment/ListOuvrageEquipmentComponent";
import ListReelEquipmentComponent from "@/components/Equipment/ListReelEquipmentComponent";
import ListSiloEquipmentComponent from "@/components/Equipment/ListSiloEquipmentComponent";
import ListTankEquipmentComponent from "@/components/Equipment/ListTankEquipmentComponent";

import BoilerSvg from "@/components/Svg/BoilerSvg";
import GroundWaterSvg from "@/components/Svg/GroundWaterSvg";
import HiveSvg from "@/components/Svg/HiveSvg";
import OuvrageSvg from "@/components/Svg/OuvrageSvg";
import ReelSvg from "@/components/Svg/ReelSvg";
import SiloSvg from "@/components/Svg/SiloSvg";
import TankSvg from "@/components/Svg/TankSvg";

import OrderCockpitRepository from "../../repositories/OrderCockpitRepository";

export default {
    name: "EquipmentPage",
    components: {
        ToolbarComponent,
        ListTankEquipmentComponent,
        BoilerSvg,
        GroundWaterSvg,
        HiveSvg,
        OuvrageSvg,
        ReelSvg,
        SiloSvg,
        TankSvg,
        ListBoilerEquipmentComponent,
        ListGroundWaterEquipmentComponent,
        ListHiveEquipmentComponent,
        ListOuvrageEquipmentComponent,
        ListReelEquipmentComponent,
        ListSiloEquipmentComponent
    },
    data() {
        return {
            tab_selected: ''
        }
    },
    computed: {
        asset_types: {
            get() {
                return this.$store.getters['asset_types/asset_types_which_have_asset'];
            },
            set() {
                this.getProfessionalAssetTypes();
            },
        }
    },
    mounted() {
        this.$pushy.register();
        this.getNotifications();
    },
}
</script>

<style>
.v-slide-group__prev {
    display: none !important;
}
</style>