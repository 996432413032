<template>
    <div id="manage-equipment-devices-component">
        <v-dialog v-model="dialog_manage_devices" max-width="500px">
            <v-card>
                <v-card-title class="text-uppercase font-weight-bold pt-8">
                    {{ $t('manage_equipment_devices_component.title') }}
                </v-card-title>
                <v-card-subtitle class="pt-2 black--text">
                    {{ $t('manage_equipment_devices_component.subtitle') }}
                </v-card-subtitle>
                <v-card-text>
                    <v-combobox
                        v-model="devices"
                        :label="$t('manage_equipment_devices_component.associated_devices')"
                        prepend-inner-icon="mdi-camera"
                        color="secondary"
                        append-icon="" filled
                    >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-for="(device, index) in item_devices"
                                :key="device.id"
                                :input-value="selected"
                                close-icon="mdi-delete"
                                small
                                class="pa-1 mt-3 mb-3"
                            >
                                <span class="pl-2">
                                    {{ device.device_number }}
                                </span>
                                <v-icon class="ml-2" @click="item_devices.splice(index, 1)">
                                    mdi-close-circle
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer/>
                    <v-btn text class="font-weight-bold" @click="dialog_manage_devices = false">{{ $t('global.cancel') }}</v-btn>
                    <v-btn text class="font-weight-bold" color="secondary" @click="dialog_manage_devices = false">
                        {{ $t('global.validate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ManageEquipmentDevicesComponent",
    data() {
        return {
            dialog_manage_devices: false,
            devices: [
                {
                    id: 1,
                    device_number: '203855'
                },
                {
                    id: 2,
                    device_number: '20385E'
                }
            ],
            item_devices: [
                {
                    id: 1,
                    device_number: '203855'
                },
                {
                    id: 2,
                    device_number: '20385E'
                }
            ],
        }
    },
    watch: {
        devices(value) {
            let test = {
                id: 10,
                device_number: value
            };

            this.item_devices.push(test);

            return test;
        }
    }
}
</script>