<template>
    <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1836_57446" fill="white">
            <rect x="9" width="27" height="6" rx="1"/>
        </mask>
        <rect x="9" width="27" height="6" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-1-inside-1_1836_57446)"/>
        <mask id="path-2-inside-2_1836_57446" fill="white">
            <rect x="9.54004" y="5" width="25.92" height="12" rx="1"/>
        </mask>
        <rect x="9.54004" y="5" width="25.92" height="12" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-2-inside-2_1836_57446)"/>
        <mask id="path-3-inside-3_1836_57446" fill="white">
            <rect x="9.54004" y="15" width="25.92" height="12" rx="1"/>
        </mask>
        <rect x="9.54004" y="15" width="25.92" height="12" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-3-inside-3_1836_57446)"/>
        <mask id="path-4-inside-4_1836_57446" fill="white">
            <rect x="12.2402" y="26" width="6.48" height="4" rx="1"/>
        </mask>
        <rect x="12.2402" y="26" width="6.48" height="4" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-4-inside-4_1836_57446)"/>
        <mask id="path-5-inside-5_1836_57446" fill="white">
            <rect x="26.2803" y="26" width="6.48" height="4" rx="1"/>
        </mask>
        <rect x="26.2803" y="26" width="6.48" height="4" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-5-inside-5_1836_57446)"/>
        <mask id="path-6-inside-6_1836_57446" fill="white">
            <rect x="20.3398" y="19" width="5.94" height="4" rx="1"/>
        </mask>
        <rect x="20.3398" y="19" width="5.94" height="4" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-6-inside-6_1836_57446)"/>
        <mask id="path-7-inside-7_1836_57446" fill="white">
            <rect x="20.3398" y="9" width="5.94" height="4" rx="1"/>
        </mask>
        <rect x="20.3398" y="9" width="5.94" height="4" rx="1" class="stroke-svg" stroke-width="3"
              mask="url(#path-7-inside-7_1836_57446)"/>
    </svg>

</template>

<script>
export default {
    name: "HiveSvg"
}
</script>